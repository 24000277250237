var Toaster = {  
    componentClass: "toasterComponent",
    hiddenClass: "toasterComponent--hidden",
    closeBtnClass: "toasterComponent-close",
    init: function () {
        var hiddenToaster = document.querySelector("." + Toaster.hiddenClass);    
        if (hiddenToaster!=null) {

            const button = document.querySelector("." + Toaster.closeBtnClass);
            const toaster = document.querySelector("." + Toaster.componentClass);

            button.addEventListener('click', function () {
                toaster.classList.toggle(Toaster.hiddenClass);
            });
           
            var delay = hiddenToaster.dataset.delay;
            if (delay) {
                setTimeout(() => {
                    hiddenToaster.classList.remove(Toaster.hiddenClass);
                }, delay*1000)
            }
        }
    },
   
}
$(function () {
    Toaster.init();
});
